.refer-page-container {
  padding: 90px 0;
}

.refer-form {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .refer-form {
    padding: 20px;
  }
}
